import React from "react";
import FileCard from "./FileCard";

const FilesList = ({ data }) => {
  return (
    <div className="space-y-2">
      {data.map((item, idx) => (
        <FileCard
          title={item.title}
          progress={item.progress}
          fileSize={item.fileSize}
          error={""}
          key={idx}
        />
      ))}
    </div>
  );
};

export default FilesList;

import Progress from "components/progress";
import React from "react";
import ImgPdf from "../../assets/svg/icons8-pdf-48.png";
import { MdDelete } from "react-icons/md";

const FileCard = ({ title, error, progress, fileSize }) => {
  return (
    <div className="flex items-center space-x-4 rounded-lg border-2 border-dashed border-gray-300 px-4 py-2">
      <img src={ImgPdf} alt="icon-pdf" className="h-10 w-10 object-cover" />
      <div className="flex w-full items-center justify-between space-x-6">
        <div className="w-full">
          <h3 className="text-sm font-semibold first-letter:capitalize">
            {title}
          </h3>
          {progress < 100 && (
            <div className="mt-2">
              <Progress />
            </div>
          )}
          {error && (
            <p className="mt-2 text-sm font-medium text-red-500">{error}</p>
          )}
          <p className="mt-1 space-x-2 ">
            <span className="text-sm font-light text-gray-500">
              {progress}%
            </span>
            <span className="text-sm font-light text-gray-500">{fileSize}</span>
          </p>
        </div>
        <div>
          <MdDelete className="h-6 w-6 cursor-pointer object-cover text-gray-500" />
        </div>
      </div>
    </div>
  );
};

export default FileCard;

import categoryService from "../services/signatureService";
import { useQuery } from "react-query";

export const useGetAllSignatures = () => {
  const { isLoading, data, isError, error, refetch } = useQuery(
    "signatures",
    () =>
      categoryService
        .getAll()
        .then((res) => res.data.data)
        .catch((err) => err.message)
  );

  return { isLoading, data, isError, error, refetch };
};

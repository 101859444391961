import React from "react";
import { MdFileUpload } from "react-icons/md";
import FilesList from "./FilesList";

const index = () => {
  return (
    <div>
      <div className="col-span-5 mb-6 h-full w-full rounded-xl bg-lightPrimary dark:!bg-navy-700 2xl:col-span-6">
        <button className="flex h-full w-full flex-col items-center justify-center rounded-xl border-[2px] border-dashed border-gray-200 py-3 dark:!border-navy-700 lg:pb-0">
          <MdFileUpload className="text-[80px] text-brand-500 dark:text-white" />
          <h4 className="text-xl font-bold text-brand-500 dark:text-white">
            Upload Files
          </h4>
          <p className="mt-2 text-sm font-medium text-gray-600">
            PNG, JPG and GIF files are allowed
          </p>
        </button>
      </div>
      <FilesList
        data={[
          {
            title: "asdfasf",
            progress: 35,
            fileSize: "45 kb",
            error: "sdfasdf",
          },
          {
            title: "asdfasf",
            progress: 35,
            fileSize: "45 kb",
            error: "sdfasdf",
          },
          {
            title: "asdfasf",
            progress: 35,
            fileSize: "45 kb",
            error: "sdfasdf",
          },
        ]}
      />
    </div>
  );
};

export default index;

import React, { useState } from "react";

import Typography from "@mui/material/Typography";

import UploadFile from "components/uploadFile";
import InputField from "components/fields/InputField";
import axios from "axios";
import signatureService from "services/signatureService";
import { useGetAllSignatures } from "hooks/useSignatureService";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import SignatureCard from "./SignatureCard";

const AddModal = ({ isOpen, onOpen, onClose }) => {
  const { isLoading, data, isError, error, refetch } = useGetAllSignatures(
    signatureService.getAll
  );

  const [name, setName] = useState("");

  if (isLoading) return "loading...";

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="2xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Tous les signatures</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <InputField
            variant="auth"
            extra="mb-3"
            label="Rechercher par nom"
            placeholder="benkhaled ..."
            id="search"
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <div className="mt-4 grid h-[300px] grid-cols-3 gap-4 overflow-y-auto">
            {data &&
              data
                .filter((item) => item.title && item.title.includes(name))
                .map((item) => <SignatureCard item={item} key={item.id} />)}
          </div>
        </ModalBody>

        <ModalFooter></ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddModal;

import React from "react";
import UploadFile from "components/uploadFile";

const DocumentForm = () => {
  return (
    <div>
      <UploadFile />
    </div>
  );
};

export default DocumentForm;

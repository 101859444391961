import React, { useEffect, useMemo, useState } from "react";
import CardMenu from "components/card/CardMenu";
import Checkbox from "components/checkbox";
import Card from "components/card";

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { FiSearch } from "react-icons/fi";
import SignatureCard from "./SignatureCard";
import { useGetAllSignatures } from "hooks/useSignatureService";
import signatureService from "services/signatureService";
import AddModal from "./AddModal";
import NewSiganture from "./NewSiganture";

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
import { useGetAllContracts } from "hooks/useContractService";
import contractsService from "services/contractsService";

const ListContracts = ({ setAdd }) => {
  const [search, setSearch] = useState("");
  const { isLoading, data, isError, error, refetch } = useGetAllContracts({
    query: search,
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    refetch();
  }, [search]);

  // if (isLoading) return "loading...";

  const getColor = (status) => {
    switch (status) {
      case "ACTIVE":
        return "text-[#4131fd] bg-[#4131fd]";
      case "DRAFT":
        return "text-[#a9a9a9] bg-[#a9a9a9]";
      default:
        return "";
    }
  };

  if (isLoading) return "loading...";

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Tous les conventions
        </div>

        <div className="flex h-10 items-center space-x-4">
          <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
            <p className="pl-3 pr-2 text-xl">
              <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
            </p>
            <input
              type="text"
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              class="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
            />
          </div>
          <button
            onClick={() => setAdd(true)}
            className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
          >
            Ajouter
          </button>
        </div>
      </header>

      <div className="mt-6">
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th className="!text-[#4131fd]">Id</Th>
                <Th className="!text-[#4131fd]">Nom partenaire</Th>
                <Th className="!text-[#4131fd]">Version</Th>
                <Th className="!text-[#4131fd]">Date signature</Th>
                <Th className="!text-[#4131fd]">Duree</Th>
                <Th className="!text-[#4131fd]">Status</Th>
                <Th className="!text-[#4131fd]">Nature</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((item, idx) => (
                <Tr className="text-sm" key={item.id}>
                  <Td>{idx + 1}</Td>
                  <Td>{item.nom}</Td>
                  <Td>{item.version}</Td>
                  <Td>{item.createdAt.substring(0, 10)}</Td>
                  <Td>{item.duree}</Td>
                  <Td>
                    {
                      <p
                        className={`w-fit rounded ${getColor(
                          item.status
                        )} bg-opacity-20 px-4 py-2 font-medium  first-letter:capitalize`}
                      >
                        {item.status}
                      </p>
                    }
                  </Td>
                  <Td>{item.nature}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </div>
    </Card>
  );
};

export default ListContracts;

import InputField from "components/fields/InputField";
import { useGetAllSignatures } from "hooks/useSignatureService";
import React, { useState } from "react";
import signatureService from "services/signatureService";
import NewSiganture from "./NewSiganture";
import AddModal from "./AddModal";

import { useDisclosure } from "@chakra-ui/react";
const SignatureForm = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <InputField
        variant="auth"
        extra="mb-3"
        label="Date de signature*"
        placeholder="20-Jan-2015"
        id="date-signature"
        type="date"
      />
      <div className="mt-8 mb-6 grid h-[500px] grid-cols-4 gap-4 overflow-x-scroll xl:overflow-x-hidden">
        <div onClick={onOpen} className="cursor-pointer">
          <NewSiganture />
        </div>
      </div>

      <AddModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
    </>
  );
};

export default SignatureForm;

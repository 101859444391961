import React, { useMemo, useState } from "react";
import CardMenu from "components/card/CardMenu";
import Checkbox from "components/checkbox";
import Card from "components/card";

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { FiSearch } from "react-icons/fi";
import SignatureCard from "./SignatureCard";
import { useGetAllSignatures } from "hooks/useSignatureService";
import signatureService from "services/signatureService";
import AddModal from "./AddModal";
import NewSiganture from "./NewSiganture";

const ListCards = (props) => {
  const { isLoading, data, isError, error, refetch } = useGetAllSignatures(
    signatureService.getAll
  );

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (isLoading) return "loading...";

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Tous les signatures
        </div>

        <div className="flex h-10 items-center space-x-4">
          <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
            <p className="pl-3 pr-2 text-xl">
              <FiSearch className="h-4 w-4 text-gray-400 dark:text-white" />
            </p>
            <input
              type="text"
              placeholder="Search..."
              class="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
            />
          </div>
        </div>
      </header>

      <div className="mt-8 mb-6 grid h-[500px] grid-cols-4 gap-4 overflow-x-scroll xl:overflow-x-hidden">
        <div onClick={handleOpen} className="cursor-pointer">
          <NewSiganture />
        </div>
        {/* {data.map((item) => (
          <SignatureCard item={item} key={item.id} />
        ))} */}
      </div>

      {/* <AddModal open={open} handleClose={handleClose} /> */}
    </Card>
  );
};

export default ListCards;

import Dropdown from "components/dropdown";
import InputField from "components/fields/InputField";
import React from "react";

const InformationForm = ({ register }) => {
  return (
    <>
      <InputField
        variant="auth"
        extra="mb-3"
        label="Annee de convention*"
        placeholder="2024"
        id="annee"
        type="number"
        min="1900" // Set minimum value for the year
        max="2100" // Set maximum value for the year
        step="1"
      />
      <InputField
        variant="auth"
        extra="mb-3"
        label="N enrigistrement de la convention*"
        placeholder="123/24"
        id="n-enrgistrement-convention"
        type="number"
      />
      <InputField
        variant="auth"
        extra="mb-3"
        label="N de la convention*"
        placeholder="123"
        id="n-convention"
        type="number"
      />
      <InputField
        variant="auth"
        extra="mb-3"
        label="Nom du partenaire*"
        placeholder="xxxx"
        id="nom-partenaire"
        type="text"
        register={register("nom")}
      />
      <select
        name=""
        id=""
        defaultValue={"0"}
        className="mb-2 w-full rounded-xl border p-3 outline-none"
        {...register("nature")}
      >
        <option value="0">--Choisir la nature du partenaire--</option>
        <option value="protocole d'accord">protocole d'accord</option>
        <option value="cooperation">cooperation</option>
        <option value="sponsoring">sponsoring</option>
        <option value="prestation">prestation</option>
        <option value="convention">convention</option>
        <option value="convention cadre">convention cadre</option>
        <option value="memorandum">memorandum</option>
        <option value="contract de partenarial">contract de partenarial</option>
      </select>

      <InputField
        variant="auth"
        extra="mb-3"
        label="Mode de partenaire*"
        placeholder="beneficiaire"
        id="mode-partenaire"
        type="text"
      />
      <InputField
        variant="auth"
        extra="mb-3"
        label="Duree de partenaire*"
        placeholder="2 ans"
        id="duree-partenaire"
        type="text"
        register={register("duree")}
      />
    </>
  );
};

export default InformationForm;

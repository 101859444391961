import axios from "axios";

const url = process.env.REACT_APP_API_URL;

const getAll = () => {
  return axios.get(`${url}/signatures`);
};

export default {
  getAll,
};

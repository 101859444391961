import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import contractsService from "services/contractsService";

export const useAddContract = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const [message, setMessage] = useState("");

  const [error, setError] = useState("");

  const { register, handleSubmit, reset, setValue, getValues, watch } = useForm(
    {}
  );

  const onSubmit = (data) => {
    setError("");
    setMessage("");
    setLoading(true);
    contractsService
      .add(data)
      .then((res) => {
        console.log(res.data.message);
        setMessage(res.data.message);
        setLoading(false);
        reset();
      })
      .catch((err) => {
        setError(err.response.data.message);
        setLoading(false);
      });
  };

  return {
    register,
    handleSubmit: handleSubmit(onSubmit),
    onSubmit,
    loading,
    message,
    error,
  };
};

export const useGetAllContracts = (filter) => {
  const { isLoading, data, isError, error, refetch } = useQuery(
    "signatures",
    () =>
      contractsService
        .getAll(filter)
        .then((res) => res.data.data)
        .catch((err) => err.message)
  );

  return { isLoading, data, isError, error, refetch };
};

import React from "react";
import TextField from "components/fields/TextField";

const CommentForm = () => {
  return (
    <div>
      <TextField label="Saisir des remarques" id="observation" rows={15} />
    </div>
  );
};

export default CommentForm;

import axios from "axios";

const url = process.env.REACT_APP_API_URL;

const filterParams = (filters) => {
  return Object.entries(filters)
    .filter(([key, value]) => value)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
};

const getAll = (filter) => {
  return axios.get(`${url}/contracts?${filterParams(filter)}`);
};

const add = (payload) => {
  return axios.post(`${url}/contracts`, payload);
};

export default {
  getAll,
  add,
};

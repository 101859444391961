import MiniCalendar from "components/calendar/MiniCalendar";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import TotalSpent from "views/admin/default/components/TotalSpent";
import PieChartCard from "views/admin/default/components/PieChartCard";
import { IoMdHome } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";

import { columnsDataCheck, columnsDataComplex } from "./variables/columnsData";

import Widget from "components/widget/Widget";
import ComplexTable from "views/admin/signatures/components/ComplexTable";
import DailyTraffic from "views/admin/signatures/components/DailyTraffic";
import TaskCard from "views/admin/signatures/components/TaskCard";
import tableDataCheck from "./variables/tableDataCheck.json";
import tableDataComplex from "./variables/tableDataComplex.json";
import ListCards from "./components/ListCards";

const Signature = () => {
  return (
    <div>
      <div className="mt-10 grid-cols-1">
        {/* Check Table */}
        <div>
          <ListCards />
        </div>
      </div>
    </div>
  );
};

export default Signature;

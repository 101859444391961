import { useState } from "react";
import ListContracts from "./components/ListContracts";
import NewContract from "./components/NewContract";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

const Signature = () => {
  const [add, setAdd] = useState(false);

  return (
    <div>
      <div className="mt-10 grid-cols-1">
        {add ? (
          <NewContract setAdd={setAdd} />
        ) : (
          <ListContracts setAdd={setAdd} />
        )}
      </div>
    </div>
  );
};

export default Signature;

import InputField from "components/fields/InputField";
import React, { useEffect } from "react";

import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import InformationForm from "./InformationForm";
import Card from "components/card";
import SignatureForm from "./SignatureForm";
import DocumentForm from "./DocumentForm";
import CommentForm from "./CommentForm";
import { BsArrowLeftCircle } from "react-icons/bs";
import { useAddContract } from "hooks/useContractService";

const NewContract = ({ setAdd }) => {
  const { register, handleSubmit, reset, setValue, getValues, watch, loading } =
    useAddContract();

  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <header className="relative flex items-center justify-between pt-4">
        <div className="flex items-center space-x-4 text-xl font-bold text-navy-700 first-letter:capitalize dark:text-white">
          <button
            onClick={() => setAdd(false)}
            className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
          >
            <BsArrowLeftCircle />
          </button>
          <p>Ajouter nouvelle convention</p>
        </div>
        {loading ? (
          <p className="text2xl font-bold">loading to save....</p>
        ) : (
          <button
            onClick={() => handleSubmit()}
            className="linear rounded-[20px] bg-lightPrimary px-4 py-2 text-base font-medium text-brand-500 transition duration-200 hover:bg-gray-100 active:bg-gray-200 dark:bg-white/5 dark:text-white dark:hover:bg-white/10 dark:active:bg-white/20"
          >
            Sauvegarder
          </button>
        )}
      </header>

      <div>
        <form className="mt-4">
          <Tabs isFitted variant="enclosed">
            <TabList mb="1em">
              <Tab>Information</Tab>
              <Tab>Signature</Tab>
              <Tab>Documents</Tab>
              <Tab>Observation</Tab>
              <Tab>Avenant</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <InformationForm
                  register={register}
                  handleSubmit={handleSubmit}
                />
              </TabPanel>
              <TabPanel>
                <SignatureForm />
              </TabPanel>
              <TabPanel>
                <DocumentForm />
              </TabPanel>
              <TabPanel>
                <CommentForm />
              </TabPanel>
              <TabPanel>
                <></>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </form>
      </div>
    </Card>
  );
};

export default NewContract;

import React from "react";

import CardMenu from "components/card/CardMenu";
import Checkbox from "components/checkbox";
import { MdDragIndicator, MdCheckCircle } from "react-icons/md";
import Card from "components/card";
import { BsPlusCircle } from "react-icons/bs";

const NewSiganture = () => {
  return (
    <Card
      extra=""
      className="h-[200px] overflow-hidden rounded-xl border border-gray-200 p-2 shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]"
    >
      {/* Signature header */}

      {/* Signature content */}

      <div className="flex h-full w-full flex-col items-center justify-center">
        <BsPlusCircle className="text-5xl text-blueSecondary" />
        <p className="mt-4 text-sm font-light text-brandLinear">
          Nouveau signature
        </p>
      </div>
    </Card>
  );
};

export default NewSiganture;

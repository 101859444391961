import CardMenu from "components/card/CardMenu";
import React from "react";
import Checkbox from "components/checkbox";
import { MdDragIndicator, MdCheckCircle } from "react-icons/md";
import Card from "components/card";
import CheckBox from "components/checkbox";

const SignatureCard = ({ item }) => {
  return (
    <Card
      extra=""
      className="h-[200px] rounded-xl border border-gray-200 p-2 shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]"
    >
      {/* Signature header */}
      <div className="relative flex items-center justify-between">
        <h4 className="font-medium text-navy-700 first-letter:capitalize dark:text-white">
          {item.title}
        </h4>
        <CheckBox />
      </div>

      {/* Signature content */}

      <div className="flex h-full w-full items-center justify-center">
        <img
          src={`${process.env.REACT_APP_BASE_URL}/uploads/${item.image}`}
          alt={item.name}
          className="w-full rounded-xl object-cover"
        />
      </div>
    </Card>
  );
};

export default SignatureCard;
